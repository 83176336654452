@tailwind base;
@tailwind components;
@tailwind utilities;

#gallerie {
  line-height: 0;
  -webkit-column-count: 3; /* split it into 5 columns */
  -moz-column-count: 3;
  column-count: 3;
}

#gallerie a {
  width: 100% !important;
  height: auto !important;
}

#gallerie img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 20px; /* to match column gap */
  border-radius: 0.2em;
}

#gallerie img:hover {
  filter: none;
}

@media (max-width: 800px) {
  #gallerie {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 400px) {
  #gallerie {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}
/* merriweather-regular - latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/merriweather-v28-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/merriweather-v28-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/merriweather-v28-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/merriweather-v28-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/merriweather-v28-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/merriweather-v28-latin-regular.svg#Merriweather")
      format("svg"); /* Legacy iOS */
}

/* merriweather-700 - latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/merriweather-v28-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/merriweather-v28-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/merriweather-v28-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/merriweather-v28-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/merriweather-v28-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/merriweather-v28-latin-700.svg#Merriweather") format("svg"); /* Legacy iOS */
}

/* merriweather-900 - latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/merriweather-v28-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/merriweather-v28-latin-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/merriweather-v28-latin-900.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/merriweather-v28-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/merriweather-v28-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/merriweather-v28-latin-900.svg#Merriweather") format("svg"); /* Legacy iOS */
}
